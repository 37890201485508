<template>
    <main id="main-section" class="h-screen bg-light-grey bg-[#0F172A]">
        <section id="top-section" class="h-screen m-auto text-center">
            <div class="container h-screen flex flex-col mx-auto justify-center text-center px-10">
                <div class="bg-white p-5 max-w-[800px] mx-auto shadow-[0_0_20px_10px_rgba(0,0,0,0.25)] whitespace-nowrap transform -rotate-6 -skew-x-12 z-10">
                    <h1 class="text-[#0F172A] text-light-grey text-3xl sm:text-4xl md:text-6xl 2xl:text-7xl font-['Poppins'] font-bold uppercase">
                        Nomadic Digital <span class="text-xs font-medium text-[#4F46E5]">LLC</span>
                    </h1>
                </div>
                <div class="bg-[#4F46E5] p-3 pl-52 mx-auto mt-3 max-w-[800px] shadow-[0_0_20px_10px_rgba(0,0,0,0.25)] transform rotate-[5deg] -skew-x-12 z-1">
                    <h2 class="text-[#38BDF8] text-dark-grey text-sm text-center font-['Poppins']">Web Development & Search Engine Optimization.</h2>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
</script>

<style scoped>
</style>
