<template>
    <div>
        <ScrollableSection />
    </div>
</template>

<script>
import ScrollableSection from './components/ScrollableSection.vue';

export default {
    name: 'App',
    components: {
        ScrollableSection,
    },
};
</script>

<style>
/* Add any global styles here */
</style>
